$primary-color: #f28c00;
$secondary-color: #595959;
$title-color: #fff;
$bg-color-light: #f9f9f9;
$font-family: "Poppins", sans-serif;
$padding: 40px;
$max-width: 1200px;

// Estilos gerais para a seção

.container-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 100px;
  width: 100%;
}

.title {
  font-family: $font-family;
  color: $primary-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.dash {
  width: 60px;
  height: 2px;
  border: 3px solid $primary-color;
  border-radius: 4px;
}

.section-text {
  max-width: 600px;
  color: $title-color;
  h2 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    color: $secondary-color;
    font-family: $font-family;
    line-height: 1.2;
  }

  p {
    font-size: 1rem;
    color: $secondary-color;
    line-height: 1.2;
    margin-bottom: 20px;
    font-family: $font-family;
  }
  a {
    text-decoration: none;
  }

  button {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    border-radius: 16px;
    color: $title-color;
    background-image: linear-gradient(
      to right,
      #d2712c,
      #d67c2b,
      #da882b,
      #de932b,
      #e09f2d
    );
    border: none;
    font-family: $font-family;
    font-size: 18px;
    cursor: pointer;
    img {
      width: 12px;
    }
    &:hover {
      background: #ad7100;
    }
  }
}

.section-image {
  img {
    width: 300px;
    height: 80vh;
    border-radius: 20px;
  }
}
.container-section-check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 4%;

  .content-check {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: linear-gradient(
      to right,
      #d2712c,
      #d67c2b,
      #da882b,
      #de932b,
      #e09b2d
    );
    border-radius: 12px;
    width: 100%;
    padding: 4%;

    .wrap {
      display: flex;
      flex-direction: column;
      background: transparent;
      border-radius: 12px;
      gap: 30px;
    }

    .text-check {
      margin-top: 40px;
      display: flex;
      align-items: center;
      gap: 40px;
      background-image: linear-gradient(
        to right,
        #f79322ec,
        #fa921b,
        #e1852b,
        #e48832,
        #e09b2d
      );
      font-family: $font-family;
      font-size: 14px;
      color: $title-color;
      padding: 20px 20px;
      border-radius: 12px 0 0 12px;
      box-shadow: 20px 12px 2px rgba(176, 88, 6, 0.132);

      img {
        width: 76px;
        height: 76px;
      }
    }
  }

  .section-image-check {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    

      .img-container{
          height: 480px;
        border-radius: 16px;
        border: 6px solid #fff;
        img{
          width: 460px;
          border-radius: 10px;
          height: 470px;
        }
      }
    
    .button-check {
      width: 240px;
      padding: 8px;
      border-radius: 12px;
      color: $title-color;
      background-image: linear-gradient(
        to right,
        #d2712c,
        #d67c2b,
        #da882b,
        #de932b,
        #e09b2d
      );
      border: none;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      border: 2px solid #fff;
      cursor: pointer;
      &:hover {
        background: #da8f03;
      }
    }
  }

  ul {
    max-width: 30vw;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    li {
      font-family: $font-family;
      color: $title-color;
      font-size: 12px;
      list-style: none;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    hr {
      margin-left: 40px;
      border: 1px solid #fff;
      width: 70%;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.text-quality {
  margin-top: 20px;
  gap: 30px;
  font-family: $font-family;
  color: #5b5a5a;
  font-size: 18px;

  img {
    width: 128px;
    height: 128px;
  }
}

.title-quality {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-family;
  font-size: 12px;
  font-weight: 600;
  color: $primary-color;
  gap: 10px;
  margin-bottom: 20px;
}

.title {
  font-family: $font-family;
  font-size: 2rem;
  color: $primary-color;
  gap: 10px;
  margin-bottom: 20px;
}

.dash {
  width: 60px;
  height: 2px;
  border: 3px solid $primary-color;
  border-radius: 4px;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.container-section-quality {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 80px;
  width: 100%;
}

.content-quality {
  display: flex;
  width: 78%;
  gap: 80px;

  ul {
    li {
      font-family: $font-family;
      color: #827a7a;
      font-weight: 500;
      list-style: none;
      margin-bottom: 10px;
      font-size: 14px;
      strong {
        color: #4a4949;
      }
    }
  }

  .section-image-quality {
    img {
      border-radius: 20px;
      width: 500px;
      height: 500px;
    }
  }
  a {
    text-decoration: none;
  }

  button {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 140px;
    padding: 6px;
    color: #4e4e4e;
    font-weight: 500;
    border: 2px solid #3f3f3f;
    font-family: $font-family;
    border-radius: 14px;
    background: transparent;
    cursor: pointer;
    img {
      width: 10px;
    }
    &:hover {
      background: #ffc18f36;
      border: 2px solid #ff7708e3;
    }
  }
}

.container-section-services {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 4%;

  .content-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(
      to right,
      #d2712c,
      #d67c2b,
      #da882b,
      #de932b,
      #e09b2d
    );
    border-radius: 12px;
    width: 90%;
    padding: 6%;
    gap: 40px;

    .text-service {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $font-family;
      font-size: 26px;
      color: #ffffffeb;

      .title-service {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 20px;
        font-size: 14px;
        .dash-service {
          width: 80px;
          height: 2px;
          border: 2px solid #fff;
          border-radius: 2px;
        }
      }
    }

    .wrap {
      display: flex;
      flex-direction: row;
      background: transparent;
      border-radius: 12px;
      gap: 20px;

      ul {
        width: 300px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        li {
          list-style: none;
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 8px;
          font-family: $font-family;
          font-weight: 600;
          font-size: 18px;
          color: #fff;
          img {
            width: 28px;
          }
        }
        hr {
          border: 1px solid #fff;
          width: 100%;
        }
      }
    }
  }
}

.container-section-results {
  padding: 4%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  .content-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .text-results {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-family: $font-family;
      font-weight: 600;
      color: $secondary-color;

      .title-result {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-family: $font-family;
        font-weight: 600;
        color: $primary-color;
        .dash-result {
          width: 80px;
          height: 2px;
          border: 2px solid $primary-color;
          border-radius: 2px;
        }
      }
    }

    img {
      width: 380px;
      height: 380px;
      border-radius: 20px;
    }
    .content-carroseul {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    .carousel {
      position: relative;
      max-width: 320px;
      overflow: hidden;
      border-radius: 10px;

      .carousel-inner {
        display: flex;
        transition: transform 0.5s ease-in-out;

        .carousel-item {
          min-width: 100%;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          text-align: center;

          img {
            width: 100%;
            height: 320px;
            border-radius: 10px;
          }

          .carousel-caption {
            font-size: 12px;
            text-align: left;
            font-family: $font-family;
            font-weight: 600;
            padding: 0 10px;
            color: #959595;
          }

          &.active {
            opacity: 1;
          }
        }
      }
      .content-carroseul {
        display: flex;
        flex-direction: row;
      }

      .carousel-control {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
        z-index: 10;

        &.prev {
          left: -60px;
        }

        &.next {
          right: -60px;
        }
      }

      .carousel-indicators {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .indicator {
          width: 6px;
          height: 6px;
          background: #ccc;
          border-radius: 60%;
          margin: 0 5px;
          cursor: pointer;

          &.active {
            background: #333;
          }
        }
      }
    }
  }
}

.container-section-work {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6% 0;
  .content-work {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 200px;
    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 28px;

      .text-work {
        font-size: 20px;
        font-family: $font-family;
        background: linear-gradient(
          to right top,
          #d2712c,
          #d67c2b,
          #da882b,
          #de932b,
          #e09f2d
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }
      a {
        width: 80%;
        button {
          width: 100%;
          padding: 12px;
          border-radius: 12px;
          color: #fff;
          font-weight: 600;
          border: none;
          background-image: linear-gradient(
            to right,
            #d2712c,
            #d67c2b,
            #da882b,
            #de932b,
            #e09f2d
          );
          cursor: pointer;
          &:hover {
            background: #ff9d00a8;
          }
        }
      }

      p {
        color: #8f8f8feb;
        font-size: 20px;
        font-weight: 600;
        font-family: $font-family;
        width: 500px;
        text-align: center;
      }
    }
    .section-image-work {
      img {
        width: 400px;
        border-radius: 10%;
        box-shadow: 2px 4px 50px 16px rgba(24, 15, 84, 0.206),
          /* sombra principal */ 0px 6px 50px rgba(0, 0, 0, 0.05); /* sombra adicional para profundidade */
        overflow: hidden;
      }
    }
  }
}

// Estilos para responsividade em telas menores
@media (max-width: 768px) {
  .section {
    padding: 20px;

    .section-content {
      flex-direction: column;
      text-align: center;

      .section-text {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 4000px) {
  .container-section-service {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .content-check {
    width: 50%;
  }
}
