$font-family: "Poppins", sans-serif;
$primary-color: #f28c00;

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8% 0;
  .adress {
    margin: 0 auto;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family;

    img {
      width: 22px;
      height: 22px;
    }
  }
  .content-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 100px;

    .wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 28px;

      .container {
        display: flex;
        height: 412px;
        flex-direction: column;
        gap: 80px;
      }
    }
    h1 {
      font-family: $font-family;
      color: $primary-color;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .text-form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input {
        width: 500px;
        padding: 12px;
        color: #4e4c4cd4;
        font-family: $font-family;
        font-weight: 500;
      }
      textarea {
        padding: 12px;
        color: #4e4c4cd4;
        font-family: $font-family;
        font-weight: 500;
      }

      button {
        cursor: pointer;
        width: 100%;
        height: 40px;
        border-radius: 0;
        border: none;
        color: #fff;
        background: #4bc15e;
        transition: 0.4s;
        &:hover{
            background: #0d7e22;
        }
      }
    }
  }
}
