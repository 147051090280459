$primary-color: #f28c00;
$secondary-color: #333;
$title-color: #ffffff;
$bg-color-light: #f9f9f9;
$font-family: "Poppins", sans-serif;
$padding: 40px;
$max-width: 1200px;

// Estilos gerais para a seção
.section {
  background-size: cover;
  background-position: right;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: rgba(223, 93, 17, 0.378);
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 76vh;
  }

  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .section-text {
    max-width: 600px;
    color: $title-color;

    h2 {
      font-size: 2.8rem;
      margin-bottom: 20px;
      color: $title-color;
      font-family: $font-family;
      line-height: 1.2;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 20px;
      font-family: $font-family;
      color: $title-color;
      font-weight: 500;
    }
    a {
      text-decoration: none;
    }
    button {
      width: 180px;
      height: 46px;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      color: $title-color;
      background-color: $primary-color;
      border: 3px solid #fff;
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      img {
        width: 10px;
      }
    }
  }
  hr {
    height: 20px;
    background: #d8842c;
  }
}

// Estilos para responsividade em telas menores
@media (max-width: 768px) {
  .section {
    padding: 20px;

    .section-content {
      flex-direction: column;
      text-align: center;

      .section-text {
        max-width: 100%;
      }
    }
  }
}
