$primary-color: #f28c00;

.footer {
  width: 100%;
  height: 56vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      padding: 1%;
    }
    .logo {
      img {
        width: 260px;
      }
    }
    .description {
      color: #fff;
      max-width: 200px;
      margin: 0 auto;
      text-align: left;
      color: #ffffff;
      font-size: 10px;
      line-height: 1.5;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }

    .social-medias {
      height: 100px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #8c8c8c;
      }
      .icons {
        ul {
          display: flex;
          flex-direction: row;
          gap: 10px;
          list-style: none;
          li {
            padding: 2px;
            background: #ffffff37;
            border-radius: 4px;
            img {
              width: 20px;
              border-radius: 100%;
              background: #fff;
              padding: 2px;
            }
          }
        }
      }
      .contacts {
        p {
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: 700;
          color: #8c8c8c;
        }
      }
    }.slider {
      width: 100%;
      height: 100px;
      overflow: hidden; /* Esconde os elementos fora do container */
      position: relative;
      display: flex;
      margin-bottom: 80px;
 
    }
    
    .slide-track {
      display: flex;
      width: calc(900px * 10); /* Largura total = largura de um logo * quantidade de logos (ajuste para sua necessidade) */
      animation: scroll 18s linear infinite; /* Controle o tempo da animação */
    }
    
    .slide {
      
      flex-shrink: 0; /* Evita que as imagens encolham */
      margin: 0 70px; /* Espaçamento entre os logos */
      filter: brightness(0) invert(1); 
    }
    
    img {
      width: 140px;
      height: 100%;
      object-fit: contain; /* Garante que a imagem se ajuste corretamente */
      /* Estilo para o logo */
    }
    
    /* Animação contínua */
    @keyframes scroll {
      from {
        transform: translateX(0); /* Início da posição */
      }
      to {
        transform: translateX(-50%); /* Move até a metade do conteúdo duplicado */
      }
    }
    
  }
  .direitos {
    width: 75%;

    margin-top: 10px;

    p {
      font-size: 12px;
      color: #fff;
    }
  }
}

