// components/Header.scss

$primary-color: #F2612C;
$secondary-color: #333;
$bg-color: #fff;
$bg-color-secondary: #E8F6FC;
$font-family: 'Poppins', sans-serif;

// Header container styles

.endereco{
  margin: 0 auto;
  padding: 4px ;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg-color-secondary;
  font-family: $font-family;

  img{
    width: 22px;
    height: 22px;
  }

}

.header {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    gap: 100px;
   
  }

  .logo {
    img{
      width: 160px;
    }
  }
 
  nav {
    .nav-links {
      display: flex;
      list-style: none;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .icon{
        width: 30px;
        height: 30px;
      }

      .icon-telefone{
        width: 28px;
        height: 28px;
      }

      .nav-text {
        margin-right: 60px;
        color: $secondary-color;
        font-size: 14px;
        font-family: $font-family;
        cursor: pointer;
        transition: color 0.3s ease;

        .title-text{
          color: $primary-color;
          font-weight: 600;
        }
        a{
          text-decoration: none;
        }
        .description-text{
          font-size: 12px;
          margin-top: -6px;
        }
      }
    }
  }
}

// Responsiveness for smaller screens
@media (max-width: 768px) {
  .header {
    .container {
      flex-direction: column;
      align-items: flex-start;
    }

    nav {
      margin-top: 10px;

      ul.nav-links {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
